<template>
  <div class="data-download">
    <div class="download-main" v-if="$store.state.application.isApplication">
      <!-- 筛选 -->
      <div class="screen">
        <el-select
          v-model="appId"
          placeholder="应用名称"
          size="small"
          class="app-name"
          @change="getProjects(1)"
        >
          <el-option
            v-for="item in appList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <div class="state">
          <div class="name">项目状态:</div>
          <el-select
            v-model="status"
            placeholder="请选择"
            size="small"
            class="app-state"
            @change="getProjects(1)"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="state">
          <!--<div class="name">类别:</div>
          <el-select
            v-model="entryType"
            placeholder="请选择"
            size="small"
            class="type-state"
            @change="getProjects(1)"
          >
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>-->
          <el-radio-group v-model="entryType" size="small" @input="getProjects(1)">
            <el-radio-button v-for="item in options2" :key="item.value" :label="item.value">{{item.label}}</el-radio-button>
          </el-radio-group>
        </div>
        <el-date-picker
          v-model="date"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="small"
          @change="changeDate"
          popper-class="picker-date-style"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>

        <div class="box">
          <el-select
            v-model="searchCateId"
            placeholder="搜索类型"
            size="small"
            class="app-name"
          >
            <el-option
              v-for="item in searchType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>

          <input
            :placeholder="`请输入项目${
              searchCateId == 1 ? 'ID' : searchCateId == 2 ? '编号' : '名称'
            }`"
            v-model="key"
            maxlength="1000"
          />
          <el-button type="primary" @click="getProjects(1)"
            >搜索 <i class="el-icon-search el-icon--right"
          /></el-button>
        </div>
      </div>
      <div class="table">
        <el-table
          height="540"
          :data="questionnaireList"
          header-row-class-name="header-row"
          :key="timeStamp"

          v-loading="surveyLoading"
          :empty-text="surveyLoading === false && questionnaireList?.length === 0 ? '暂无数据' : '  ' "
          element-loading-text="加载中..."
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(255, 255, 255, .1)"
        >
          <el-table-column type="index" label="序号" width="50">
          </el-table-column>
          <el-table-column prop="projectId" label="ID号" width="100">
          </el-table-column>
          <el-table-column prop="number" label="项目编号" width="150">
          </el-table-column>
          <el-table-column prop="title" label="问卷名称" min-width="100">
            <template slot-scope="scope">
              <el-tooltip placement="top" effect="dark">
                <div slot="content">{{ scope.row.title }}</div>
                <div class="no-wrap">{{ scope.row.title }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="状态" width="110">
            <template slot-scope="scope">
              <div class="row-state">
                <span
                  v-if="scope.row.status === 0"
                  style="background: #698af7"
                ></span>
                <span
                  v-if="scope.row.status === 1"
                  style="background: #ff8940"
                ></span>
                <span
                  v-if="scope.row.status === 2"
                  style="background: #72daff"
                ></span>
                <span
                  v-if="scope.row.status === 3"
                  style="background: #81e0af"
                ></span>
                <span
                  v-if="scope.row.status === 4"
                  style="background: #f56c6c"
                ></span>
                <div v-if="scope.row.status === 0">进行中</div>
                <div v-if="scope.row.status === 1">暂停</div>
                <div v-if="scope.row.status === 2">数据审核</div>
                <div v-if="scope.row.status === 3">已完成</div>
                <div v-if="scope.row.status === 4">已撤销</div>
              </div>
            </template>
          </el-table-column>
       
          <el-table-column
            prop="cpi2Complete"
            label="单价"
            width="100"
            sortable
          >
            <template slot-scope="scope"
              >￥ {{ scope.row.cpi2Complete }}
            </template>
          </el-table-column>
          <el-table-column
            prop="maxComplete"
            label="配额量"
            width="100"
            sortable
          >
          </el-table-column>
          <el-table-column
            prop="extraCpi2Complete"
            label="额外单价"
            width="100"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.extraCpi2Complete !== null"
                >￥ {{ scope.row.extraCpi2Complete }}</span
              >
              <span v-else>_</span>
            </template>
          </el-table-column>
          <el-table-column prop="completes" label="完成量" width="100">
          </el-table-column>
          <el-table-column prop="earnings" label="总费用" width="100">
             <template slot-scope="scope">
              ￥ {{ scope.row.earnings }}
             </template>
          </el-table-column>

          <el-table-column
            prop="countryId"
            label="执行国家地区"
            min-width="120"
          >
            <template slot-scope="scope">
              <div class="no-wrap">
                {{ scope.row.countryId | getCountrys(countrys) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="inspectionDate" label="审核时间" width="160">
             <template slot-scope="scope">
              <span v-if="scope.row.inspectionDate===null">_</span>
              <span v-else>{{scope.row.inspectionDate}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="350"  fixed="right">
            <template slot-scope="scope">
              <div class="operate">
                <div
                  class="left"
                  v-if="scope.row.downloadState == 1"
                  @click="getParticipations(scope.row, scope.$index)"
                >
                  下载数据
                </div>
                <div class="left progress" v-if="scope.row.downloadState == 2">
                  <el-progress
                    :percentage="scope.row.downLoadProgress"
                    color="#68EBB5"
                  ></el-progress>
                </div>
                <div class="left complete" v-if="scope.row.downloadState == 3">
                  下载数据
                </div>
                <div
                  class="right"
                  v-if="scope.row.downloadStateQc == 1"
                  @click="getBadData(scope.row, scope.$index)"
                >
                  下载QC数据
                </div>
                <div class="right" v-if="scope.row.downloadStateQc == 2">
                  <el-progress
                    :percentage="scope.row.downLoadProgressQc"
                    color="#68EBB5"
                  ></el-progress>
                </div>
                <div
                  v-if="scope.row.downloadStateQc == 3"
                  class="right complete"
                  @click="getBadData(scope.row, scope.$index)"
                >
                  下载QC数据
                </div>

                <div
                  class="left"
                  v-if="scope.row.downloadStateRe == 1"
                  @click="getCashLog(scope.row, scope.$index)"
                >
                  下载奖励数据
                </div>
                <div
                  class="left progress"
                  v-if="scope.row.downloadStateRe == 2"
                >
                  <el-progress
                    :percentage="scope.row.downLoadProgressRe"
                    color="#68EBB5"
                  ></el-progress>
                </div>
                <div
                  class="left complete"
                  v-if="scope.row.downloadStateRe == 3"
                >
                  下载奖励数据
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination-box">
        <span class="total-pages">共{{ PageCount }}页</span>
        <el-pagination
          @size-change="getProjects"
          @current-change="getProjects"
          :current-page.sync="page"
          :page-size="size"
          background
          layout="prev, pager, next,jumper"
          :total="total"
        >
        </el-pagination>
      </div>

      <div class="footer-fa">
        <Footer></Footer>
      </div>
    </div>
    <Application v-else></Application>
  </div>
</template>
<script>
import Breadcrumb from "@/components/Breadcrumb";
import Footer from "@/components/Footer/homePageFooter.vue";
import Application from "@/components/Application";
import {
  getProjects,
  getCountryAll,
  getProjectsDetails,
  getApps,
  getParticipations,
  getBadData,
  getCashLog,
} from "@/api/app";
import request from "@/utils/request";
export default {
  components: { Breadcrumb, Footer, Application },
  filters: {
    getCountrys(value, countrys) {
      let name = null;
      countrys.forEach((element) => {
        if (element.id === value) {
          name = element.name;
          return;
        }
      });
      return name;
    },
  },
  data() {
    return {
      value: "",
      value1: "",
      options: [
        {
          value: null,
          label: "全部",
        },
        {
          value: 0,
          label: "进行中",
        },
        {
          value: 1,
          label: "暂停",
        },
        {
          value: 2,
          label: "数据审核",
        },
        {
          value: 3,
          label: "已完成",
        },
        {
          value: 4,
          label: "已撤销",
        },
      ],
      options2: [
        {
          value: null,
          label: "全部",
        },
        {
          value: 1,
          label: "个案",
        },
        {
          value: 2,
          label: "API",
        },
      ],
      appList: null,
      questionnaireList: null,
      dialogVisible: false,
      countrys: null,
      remark: null, //配额详情
      date: null,
      searchType: [
        {
          value: 1,
          label: "问卷ID",
        },
        {
          value: 2,
          label: "问卷编号",
        },
        {
          value: 3,
          label: "问卷名称",
        },
      ],
      total: null,
      page: 1,
      size: 10,
      PageCount: null,
      appId: null,
      status: null,
      startDate: null,
      endDate: null,
      searchCateId: 1,
      key: null,
      entryType: 1,
      downLoadProgress: 0, //下载进度
      timeStamp: "", //时间戳
      surveyLoading: true
    };
  },
  created() {
    //先获取全部国家
    if (this.$store.state.application.isApplication) {
      getCountryAll().then((res) => {
        if (res.status === 200) {
          this.countrys = res.data;
          //获取列表
          this.getApps();
        }
      });
    }
  },
  computed: {
    isApplication() {
      return this.$store.state.application.isApplication;
    },
  },
  watch: {
    questionnaireList() {
      console.log("触发");
      this.timeStamp = new Date().valueOf();
    },
    isApplication(newValue) {
      if (newValue) {
        getCountryAll().then((res) => {
          if (res.status === 200) {
            this.countrys = res.data;
            //获取列表
            this.getApps();
          }
        });
      }
    },
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    //改变日期范围
    changeDate() {
      if (this.date) {
        this.startDate = this.date[0];
        this.endDate = this.date[1];
      } else {
        this.startDate = "";
        this.endDate = "";
      }
      this.page = 1;
      this.getProjects();
    },
    //复制
    copyContent(data) {
      this.$copyText(data).then(
        () => {
          this.$message.success("复制成功");
        },
        () => {
          this.$message.error("复制失败");
        }
      );
      this.handleClose();
    },
    //配额详情
    quotaDetails(id) {
      getProjectsDetails(id).then((res) => {
        if (res.status === 200) {
          this.dialogVisible = true;
          this.remark = res.data.remark;
        }
      });
    },
    //获取问卷列表
    getProjects(type) {
      this.surveyLoading = true;
      this.questionnaireList = null;

      if (type === 1) {
        this.page = 1;
      }
      console.log("this.page", this.page);
      const params = {
        page: this.page,
        size: this.size,
        appId: this.appId,
        status: this.status,
        startDate: this.startDate,
        endDate: this.endDate,
        searchCateId: this.searchCateId,
        key: this.key,
        entryType: this.entryType,
      };
      getProjects(params).then((res) => {
        this.questionnaireList = res.data.data;
        this.total = res.data.dataCount;
        this.PageCount = res.data.pageCount;
        //downloadState 1没下载过 2下载中 3下载完成
        //downLoadProgress 下载进度
        //downloadStateQc 1没下载过 2下载中 3下载完成
        //downLoadProgressQc Qc下载进度
        //downloadStateRe  1没下载过 2下载中 3下载完成
        //downLoadProgressRe  Re下载进度
        this.questionnaireList.forEach((_, index) => {
          this.questionnaireList[index].downloadState = 1;
          this.questionnaireList[index].downLoadProgress = 0;
          this.questionnaireList[index].downloadStateQc = 1;
          this.questionnaireList[index].downLoadProgressQc = 0;
          this.questionnaireList[index].downloadStateRe = 1;
          this.questionnaireList[index].downLoadProgressRe = 0;
        });

        this.surveyLoading = false;
        console.log("this.questionnaireList", this.questionnaireList);
      });
    },
    //获取应用列表
    getApps() {
      const params = {
        page: 1,
        size: 100,
      };
      getApps(params).then((res) => {
        if (res.status === 200) {
          this.appList = res.data.data;
          this.appId = res.data.data[0].id;
          console.log("this.appList", this.appList);
          this.getProjects();
        }
      });
    },
    //数据下载
    getParticipations(row, rIndex) {
      console.log("row", row, rIndex);
      const params = {
        id: row.projectId,
        appId: this.appId,
      };
      this.questionnaireList[rIndex].downloadState = 2;
      this.questionnaireList[rIndex].downLoadProgress = 0;
      this.questionnaireList = this.questionnaireList.concat();
      console.log(1233445, this.questionnaireList);
      getParticipations(params).then((res) => {
        let that = this;
        if (res.status === 200) {
          request({
            method: "get",
            responseType: "blob",
            url: process.env.VUE_APP_SERVER + `api/v1/File/Down`,
            params: {
              filename: res.data,
            },
            onDownloadProgress(progressEvent) {
              //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
              //如果lengthComputable为false，就获取不到progressEvent.total和progressEvent.loaded
              that.questionnaireList[rIndex].downLoadProgress = parseInt(
                (progressEvent.loaded / progressEvent.total) * 100
              ); //实时获取最新下载进度
              that.questionnaireList = that.questionnaireList.concat();
            },
          }).then((res) => {
            console.log("数据下载完成", res);
            this.questionnaireList[rIndex].downloadState = 3;
            this.questionnaireList = this.questionnaireList.concat();
            if (!res) {
              return;
            }
            // 无法导出时，报错的问题的处理
            if (res.type == "application/json") {
              const reader = new FileReader();
              reader.readAsText(res, "utf-8");
              reader.onload = function () {
                const msg = JSON.parse(reader.result);
                console.log("无法处理", msg);
              };
              return;
            }
            // 以下载xls文件为例
            let fileName = row.title;
            let suffix = "xls";
            let url = window.URL.createObjectURL(new Blob([res]));
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", fileName + "." + suffix);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
          });
        }
      });
    },
    //qc数据下载
    getBadData(row, rIndex) {
      const params = {
        id: row.projectId,
        appId: this.appId,
      };
      this.questionnaireList[rIndex].downloadStateQc = 2;
      this.questionnaireList[rIndex].downLoadProgressQc = 0;
      this.questionnaireList = this.questionnaireList.concat();
      console.log(1233445, this.questionnaireList);
      getBadData(params).then((res) => {
        let that = this;
        if (res.status === 200) {
          request({
            method: "get",
            responseType: "blob",
            url: process.env.VUE_APP_SERVER + `api/v1/File/Down`,
            params: {
              filename: res.data,
            },
            onDownloadProgress(progressEvent) {
              //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
              //如果lengthComputable为false，就获取不到progressEvent.total和progressEvent.loaded
              that.questionnaireList[rIndex].downLoadProgressQc = parseInt(
                (progressEvent.loaded / progressEvent.total) * 100
              ); //实时获取最新下载进度
              that.questionnaireList = that.questionnaireList.concat();
            },
          }).then((res) => {
            console.log("数据下载完成", res);
            this.questionnaireList[rIndex].downloadStateQc = 3;
            this.questionnaireList = this.questionnaireList.concat();
            if (!res) {
              return;
            }
            // 无法导出时，报错的问题的处理
            if (res.type == "application/json") {
              const reader = new FileReader();
              reader.readAsText(res, "utf-8");
              reader.onload = function () {
                const msg = JSON.parse(reader.result);
                console.log("无法处理", msg);
              };
              return;
            }
            // 以下载xls文件为例
            let fileName = row.title;
            let suffix = "xls";
            let url = window.URL.createObjectURL(new Blob([res]));
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", fileName + "." + suffix);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
          });
        }
      });
    },
    //下载奖励数据
    getCashLog(row, rIndex) {
      const params = {
        id: row.projectId,
        appId: this.appId,
      };
      this.questionnaireList[rIndex].downloadStateRe = 2;
      this.questionnaireList[rIndex].downLoadProgressRe = 0;
      this.questionnaireList = this.questionnaireList.concat();
      console.log(1233445, this.questionnaireList);
      getCashLog(params).then((res) => {
        let that = this;
        if (res.status === 200) {
          request({
            method: "get",
            responseType: "blob",
            url: process.env.VUE_APP_SERVER + `api/v1/File/Down`,
            params: {
              filename: res.data,
            },
            onDownloadProgress(progressEvent) {
              //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
              //如果lengthComputable为false，就获取不到progressEvent.total和progressEvent.loaded
              that.questionnaireList[rIndex].downLoadProgressRe = parseInt(
                (progressEvent.loaded / progressEvent.total) * 100
              ); //实时获取最新下载进度
              that.questionnaireList = that.questionnaireList.concat();
            },
          }).then((res) => {
            console.log("数据下载完成", res);
            this.questionnaireList[rIndex].downloadStateRe = 3;
            this.questionnaireList = this.questionnaireList.concat();
            if (!res) {
              return;
            }
            // 无法导出时，报错的问题的处理
            if (res.type == "application/json") {
              const reader = new FileReader();
              reader.readAsText(res, "utf-8");
              reader.onload = function () {
                const msg = JSON.parse(reader.result);
                console.log("无法处理", msg);
              };
              return;
            }
            // 以下载xls文件为例
            let fileName = row.title;
            let suffix = "xls";
            let url = window.URL.createObjectURL(new Blob([res]));
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", fileName + "." + suffix);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.data-download {
  padding: 20px 0 0 20px;
}
.download-main {
  width: 100%;
  min-height: calc(100vh - 85px);
  background-color: #fff;
  .screen {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 21px 24px 0 24px;
    .app-name {
      width: 100px;
    }
    .state {
      display: flex;
      align-items: center;
      .name {
        font-size: 14px;
        margin-right: 7px;
      }
      .app-state {
        width: 100px;
      }
      .type-state {
        width: 100px;
      }
    }
    .box {
      display: flex;
      align-items: center;
      input {
        width: 120px;
        height: 32px;
        border: 1px solid #dddddd;
        border-radius: 4px;
        padding-left: 10px;
        margin-right: 7px;
        margin-left: 10px;
      }
      .el-button {
        background-color: #4578ff;
        color: #fff;
        height: 32px;
        width: 84px;
        padding: 0;
      }
    }
  }
}
.table {
  padding: 0 24px 0 24px;
  width: calc(100% - 48px);
  .el-table {
    width: 100%;
    margin-top: 27px;
    /deep/ .header-row {
      background-color: #f5f7fd;
      .el-table__cell {
        background-color: #f5f7fd;
        color: #06052b;
        font-size: 14px;
        font-weight: normal;
        text-align: center;
      }
    }
    /deep/ .el-table__cell {
      text-align: center;
    }
    .no-wrap {
      white-space: nowrap;
      word-break: keep-all;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .row-state {
      display: flex;
      align-items: center;
      span {
        width: 7px;
        height: 7px;
        background: #698af7;
        border-radius: 7px;
        margin-right: 8px;
        margin-left: 10px;
      }
    }
    .operate {
      display: flex;
      justify-content: center;
      font-size: 14px;
      color: #4578ff;
      .left {
        margin-right: 0px;
        width: 100px;
      }
      .right {
        width: 100px;
      }
      .complete {
        color: #9091a1;
      }
      .progress {
        width: 100px;
      }
      div {
        cursor: pointer;
      }
    }
  }
}
.pagination-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  .total-pages {
    color: #323233;
    font-size: 14px;
    margin-right: 18px;
  }
  .el-pagination {
    margin-right: 20px;
  }
}
.footer-fa {
  padding-right: 20px;
  background-color: #fff;
  /deep/ .home-page-footer {
    margin-bottom: 0 !important;
    padding-bottom: 25px;
  }
}
.questionnaire-list {
  /deep/ .el-dialog__header {
    padding: 24px 24px 0 24px;
    .el-dialog__title {
      font-weight: bold;
      font-size: 16px;
    }
    .el-dialog__close {
      color: #000;
    }
  }

  /deep/ .el-dialog__body {
    padding: 24px;
    .content {
      padding: 0 40px;
      color: #666666;
      font-size: 16px;
      line-height: 36px;
    }
    .button-fa {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      .el-button {
        width: 84px;
        height: 32px;
        background: #4578ff;
        border-radius: 4px 4px 4px 4px;
        color: #fff;
        padding: 0;
      }
    }
  }
}
</style>